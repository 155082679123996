// Import Bootstrap and its default variables
// @import "~bootstrap/scss/bootstrap.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

@font-face {
    font-family: 'BentonSans';
    font-style: normal;
    font-weight: normal;
    src: local('BentonSans Book'), url('/assets/fonts/BentonSans Book.otf') format('opentype');
}

@font-face {
    font-family: 'BentonSans';
    font-style: normal;
    font-weight: 200;
    src: local('BentonSans Regular'), url('/assets/fonts/BentonSans Regular.otf') format('opentype');
}

@font-face {
    font-family: 'BentonSans';
    font-style: normal;
    font-weight: 300;
    src: local('BentonSans Medium'), url('/assets/fonts/BentonSans Medium.otf') format('opentype');
}

@font-face {
    font-family: 'BentonSans';
    font-style: normal;
    font-weight: 500;
    src: local('BentonSans'), url('/assets/fonts/BentonSans Bold.otf') format('opentype');
}

@font-face {
    font-family: 'BentonSans';
    font-style: normal;
    font-weight: 700;
    src: local('BentonSans Black'), url('/assets/fonts/BentonSans Black.otf') format('opentype');
}
  

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'BentonSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@import 'react-redux-toastr/src/styles/index';

.btn-sm{
    padding: 0.25rem 0.5rem !important;
}

.btn-link{
    width: auto !important;
}