@import 'base';

*{
  color: #454545;
}

.table{
  th{
    font-weight: 300;
  }
}

.aligmentmeleft {
    text-align: left;
}

p{
  font-size: 16px;
  font-weight: 200;
  line-height: 26px;
}

h1{
  font-size: 32px;
  line-height: 32px;
  font-weight: 300;
}

.navbar {
  background: #FFFFFF;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #D1D1D1;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
}

.container-nx {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  color: #EE752F;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;

    & > img {
        height: 70px;
    }
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: end;
}

.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #f00946;
}

.nav-links {
  color: #333333;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.navlogwapper-p {
    width: 100%;
    flex: 1 1;
    display: flex;
    align-items: center;
}

.fa-bars {
  color: #333;
}

.menu-icon {
  display: none;
}

.bg-orange {
  background-color: #EF752F;
}

h1, h4{
  color: #555555;
}

h5{
  font-weight: 300;
}

.btn-custom-link{
  font-weight: 300;
  text-decoration: none !important;
  outline: none;
  font-size: 12px;
  text-transform: uppercase;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #FFFFFF;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .navbar-logo {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(25%, -50%);
    
    & > img {
        height: 50px;
    }
}

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
}

.home__hero-section {
  color: #fff;
  padding: 160px 0;
}

.home__hero-row {
  align-items: center;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapper {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
}

.top-line {
  color: #f00946;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.heading {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;
}

.dark {
  color: #1c2237;
}

.darkBg {
  background-color: #1c2237;
}

.home__hero-subtitle {
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
}

.home__hero-img-wrapper {
  max-width: 555px;
}

.home__hero-img {
  max-width: 95%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
  .home-carousel .custom-slider-item img.carousel-cover-image {
      display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .Register-Now {
      display: block !important;
  }
  .Register-Now p {
      font-size: 16px;
  }
  .home__hero-text-wrapper {
    padding-bottom: 65px;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
}

:root {
  --primary: #EE752F;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #333;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--mobile {
  text-align: center;
  border-radius: 4px;
  width: 80%;
  text-decoration: none;
  font-size: 1.5rem;
  background-color: transparent;
  color: #333;
  padding: 14px 20px;
  border: 1px solid #333;
  transition: all 0.3s ease-out;
}

.btn--wide {
  padding: 12px 64px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover,
.btn--mobile:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
}

.btn--wide:hover {
  color: #fff;
  background-color: #f00946;
  transition: all 0.2s ease-out;
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
}

.blue {
  background-color: #276afb;
  color: #fff;
  border: none;
}

.red {
  background-color: #f00946;
  color: #fff;
  border: none;
}

.primary {
  background-color: #242424;
  color: #fff;
  border: none;
}

.primary:hover {
  background-color: #fff;
  color: #242424;
  border: none;
}

.green {
  background: #25ce4a;
  color: #fff;
  border: none;
}

.green:hover {
  background-color: #242424;
}

.icon {
  margin: 24px 0;
}



/* IMAGINE TREE RELATED */
a{
  text-decoration: none;
  color: #454545;
}

.it-navbar{
    height: 100px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 999;
    border-bottom: 1px solid #E1E1E1;

    & > .it-navbar-container{
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
        padding-right: 50px;
        padding-left: 50px;
        justify-content: space-between;

        & > .it-navbar-left{
            flex: 1;
        }
    
        & > .it-navbar-center{
            flex: 4;
            height: 80px;

            & > ul {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                list-style: none;

                & > li {
                    text-align:center;
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: 300;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    letter-spacing: 0.03em;
                    text-transform: uppercase;
                    height: 100%;
                    margin-left: 40px;
                    color: #454545;

                    &:first-child{
                        margin-left: 0px;
                    }
                }

                a{
                    text-decoration: none;
                    color: #454545;
                }

                .dropdown-menu{
                    position: absolute;
                    border: 1px solid #F1f1f1;
                    top: 60px;
                    background-color: #FFFFFF;
                    box-shadow: 0 0 8px rgba(0,0,0,.1);
                    border-radius: 4px;
                    list-style: none;
                    overflow: hidden;

                    & > li{
                        text-align: left;
                        text-transform: none;
                        min-width: 150px;

                        &:hover{
                            background-color: rgba(0,0,0,.1);
                        }

                        & > a {
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          padding: 16px 15px;

                          & > span{
                            margin-right: 8px;
                          }
                        }
                    }
                }
            }
        }
    
        & > .it-navbar-right{
            flex: 1;
            display: flex;
            align-items: center;

            .it-navbar-auth{
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
        }
    }
}

.it-mobile-navbar{
  display: none;
  height: 60px;
  background-color: #FFFFFF;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #E1E1E1;
  padding: 6px 16px;
  overflow: hidden;
  
  .nav-logo > a > img{
    height: 42px;
    width: auto;
  }

  .right-nav-bar{
    svg{
      font-size: 24px;
    }
  }

  .mobile-nav-menu{
    position: fixed;
    top: 60px;
    left: -100%;
    opacity: 0.5;
    transition: all 0.6s ease;
    width: 100%;
    overflow-y: auto;
    bottom: 0px;
  }

  .mobile-nav-menu.active{
    background-color: #FFFFFF;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  ul.main-menu{
    list-style: none;
    & > li{
      font-size: 24px;
      line-height: 48px;
      font-weight: 300;
      padding: 15px 16px;
      border-bottom: 1px solid #E1E1e1;

      .user-logo{
        width: 38px;
        height: 38px;
        border-radius: 50%;
        margin-right: 8px;
        object-fit: cover;
        overflow: hidden;
        border: 2px solid #EF752F;
        text-align: center;
        line-height: 38px;
        color: #EF752F;
        background-color: #FFFFFF;
        font-size: 16px;
        display: inline-block;
        font-weight: 500;
        vertical-align: middle;
      }

      .expand-icon{
        float: right;
        clear: both;
        font-size: 20px;
      }

      ul.sub-menu{
        margin-left: 20px;
        padding: 0px;
        list-style: none;
        height: 0px;
        overflow: hidden;
        transition: all 0.6s ease;

        & > li{
          font-size: 18px;
          font-weight: 200;
          a{
            color: var(--primary);
            display: block;
          }
        }
      }

      .sub-menu.active{
        height: 200px;
      }

      &.user-points-item{
        
        .user-points{
          display: flex;
          align-items: center;

          .badge-icon{
            width: 42px;
            height: 42px;
          }

          .point-progress-bar{
            width: 100%;
            margin-left: 4px;
            .point-labels{
              font-size: 12px;
              display: flex;
              justify-content: space-between;
              font-weight: 200;
            }

            .progress-bar{
              height: 8px;
              border-radius: 8px;
              background-color: #E1E1E1;
              position: relative;

              .active-progres{
                background-color: #595c97;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                border-radius: 8px;
              }
            }
          }
        }
      }
    }
  }
}

.it-nav-menu-strip{
  background-color: #F5F8FF;
  height: 56px;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;

  & > ul{
    list-style: none;
    text-align: center;
    & > li {
      display: inline-block;
      min-width: 100px;
      text-align: center;

      a{
        font-weight: 300;
        line-height: 56px;
        font-size: 18px;
        

        &:hover{
          color: #000000;
        }
      }
    }
  }
}

.it-container{
  max-width: 1300px;
  width: 100%;
}

.it-hero-section{
  min-height: 300px;
}

.btn-it-primary{
  background-color: #EF752F;
  border-radius: 50px;
  padding: 0px 16px;
  color: #FFFFFF;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 300;
  margin: auto;
  font-size: 14px;
  line-height: 37px;
  height: 37px;
  letter-spacing: 0.03em;
  border: 2px solid #EF752F;
  display: inline-block;
  &:hover{
    color: white;
    text-decoration: none;
  }
  &:disabled{
    opacity: 0.6;
    cursor: default;
  }
  &.outlined{
    background-color: transparent;
    color: #EF752F; 
  }
}

.btn-it-secondary{
  background-color: transparent;
  border-radius: 50px;
  padding: 0px 16px;
  color: #EF752F;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 300;
  margin: auto;
  font-size: 14px;
  line-height: 37px;
  height: 37px;
  display: inline-block;
  letter-spacing: 0.03em;
  border: 2px solid #EF752F;

  &:hover{
    color: #EF752F;
    text-decoration: none;
  }
}

.btn-it-link{
  color: #333333;
  display: flex;
  text-decoration: none;
  margin: 4px 2px;
  font-size: 14px;
  text-transform: uppercase;
}

.dark-link{
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);

  &:hover{
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
  }
}

.it-footer{
  background-color: #F9F9F9;
  padding: 56px 0px;

  & > .container{
    max-width: 1300px;
  }

  .footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      height: 80px;
      
    }
  }
  .footer-logo.powered-by-logo > img{
    max-width: 180px;
    height: auto;
  }

  .it-footer--menu{
    list-style: none;

    & > li {
      line-height: 1.8em;
      padding: 2px;
      h5{
        color: #343434;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
      }
      a{
        font-weight: 300;
        text-decoration: none;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  .it-footer--social-links{
    text-align: center;
    margin-top: 50px;
    font-size: 1.4em;

    & > a{
      margin: 0px 16px;
      color: #454545;
    }
  }

  .it-footer--terms-links{
    margin-top: 50px;
    & > a{
      text-decoration: none;
      font-size: 12px;
      line-height: 18px;
      font-weight: 300;
    }
  }
}

.it-main--container{
  min-height: 300px;
  margin: 0px;
}

.block-50{
  min-height: calc(100vh - 80px);
}

.auth-ui-container{
  width: 450px;

  & > .social-buttons{
    & > .btn-round{
      margin-bottom: 16px;

      &:first-child{
        margin-top: 16px;
      }
    }
  }
}

.divider{
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
  position: relative;
  display: block;
  margin-left: 20%;
  margin-right: 20%;

  > span{
    background-color: white;
    display: inline-block;
    padding: 20px 10px;
    font-weight: 500;
    color: #919191;
  }

  &:before{
    content: '';
    height: 1px;
    background-color: #A1A1A1;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: -1;
  }
}

.btn-round{
  border-radius: 25px;
  font-weight: 300;
  text-transform: uppercase;
  min-height: 50px;
  margin-bottom: 38px;
}

.fix-rounded-right {
  border-top-right-radius: .2rem !important;
  border-bottom-right-radius: .2rem !important;
}

.input-group-text, .form-control{
  background-color: #F5F5F5;
  color: #487576;
}

.form-group > label{
  color: #487576;
  font-size: 0.9rem;
}

.pos-r {
    position: relative;
}

.zx-9
{
  z-index: 9;
}

.zx-10
{
  z-index: 10;
}

.onboard-form-container{
  width: 80%;

  & > .progress{
    height: 6px;
    margin-top: 140px;
  }

  .btn-light{
    color: var(--primary);
    font-weight: 300;
  }
}

.custom-select-container{
  width: 100%;

  .custom-select__control{
    min-height: calc(2.0em + 1rem + 2px);
    background-color: #F5F5F5;
    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }
  .custom-select__control--is-focused{
    color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  .custom-select__input > input{
    padding: 5px 0rem !important;
  }
  .custom-select__multi-value{
    background-color: #F2DFD4;
    

    .custom-select__multi-value__label, .custom-select__multi-value__remove{
      color: #EE752F;
      font-weight: 300;
    }
  }
}

.btn-checkbox-group{
  div{
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 5px 10px;
    background: #FAFAFA;
    transition: 300ms all;

    & > input{
      position: absolute;
      top: -20px
    }

    & > label{
      margin-bottom: 0px;
      color: #757575;
      font-weight: 300;
      font-size: 15px;
      line-height: 14px;
      padding: .7rem 1rem;
    }

    & > input:checked ~ label{
      background-color: #FCE3D4;
      color: #EE752F;
    }

    &:hover{
      background-color: #FCE3D4;
      cursor: pointer;
      label{
        cursor: pointer;
      }
    }
  }

  div.btn-checkbox-pill{
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 20px;

    & > label{
      color: rgba(255, 255, 255, 0.8);
    }

    &:hover{
      background-color: white;
      cursor: pointer;
      label{
        cursor: pointer;
        color: #EE752F;
      }
    }
  }
}

b{
  font-weight: 300;
}

.tag-pill{
  color: #EE752F;
  border-radius: 22px;
  background-color: #F2DFD4;
  text-transform: capitalize;
  padding: 5px 11px;
  font-size: 12px;
  line-height: 18px;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 200;
}

/* READ POST RELATED STYLES */
.post-container{
  .row{
    margin-bottom: 26px;
  }

  .post__title{
    color: #575555;
    font-size: 52px;
    line-height: 65px;
  }

  .user-and-actions-container{
    margin-top: 10px;
    .right-box{
      .share-btn{
        display: inline-flex;
        align-items: center;
        margin-right: 16px;

        &:last-child{
          margin-right: 0px;
        }
        
        span{
          font-weight: 300;
          color: #EE752F;
          margin-right: 8px;
        }
        svg{
          cursor: pointer;
          fill: #EF752F;
          font-size: 28px;
        }
      }
    }
  }

  .post-featured-image{
    min-height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; 
  }

  .white-gradient{
    background:linear-gradient(rgba(0,0,0,0.0), rgba(255,255,255,0.7), #FFFFFF);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 60%;
  }

  .story__create-account-alert{
    margin-top: -64px;
    & > div{
      max-width: 480px;
      margin: 36px auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 4px solid #EF752F;
      text-align: center;
      padding: 20px 40px;
      background-color: #FFFFFF;
    }

    h3{
      font-weight: 200;
      font-size: 24px;
    }
  }

  .post__next-last-bottom-nav{
    .row > div{
      padding: 0px;
      height: 100%;
      height: 400px;
      overflow: hidden;

      img{
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -1;
      }

      .post-box{
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0px 38px;

        a{
          text-decoration: none;
          width: 100%;
        }

        p{
          font-size: 14px;
          line-height: 28px;
          font-weight: 700;
          color: var(--primary);

          svg{
            fill: var(--primary);
            font-size: 28px;
          }
        }

        h3{
          color: rgba(255, 255, 255, 0.9);
        }
      }
    }

    .last-post{
      .post-box{
        background: linear-gradient(270deg, rgba(28, 67, 128, 0) 15.19%, rgba(28, 67, 128, 0.37) 41.21%, #1C4380 100%);
      }
      .post-box.no-post{
        background-color: #1C4380;
      }
    }

    .next-post{
      .post-box{
        text-align: right;
        justify-content: right;
        background: linear-gradient(90deg, rgba(28, 67, 128, 0) 15.19%, rgba(28, 67, 128, 0.37) 41.21%, #1C4380 100%);
      }
      .post-box.no-post{
        background-color: #1C4380;
        & > h3{
          display: block;
          text-align: right;
          width: 100%;
        }
      }
    }
  }
}

.content-blocks{
  & > div{
    margin-bottom: 48px;
    font-size: 18px;
    color: #333333;
    font-weight: 300;

    p{
      font-size: 18px;
      line-height: 32px;
      font-weight: 200;
    }
  }
}

@media screen and (max-width: 1200px) {
  /*
  .btn-it-primary{
    background-color: #EF752F;
    border-radius: 50px;
    padding: 12px 20px 10px 20px;
    color: #FFFFFF;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 300;
    margin: auto;
    font-size: 12px;
  }
  */
  .btn-it-link{
    font-size: 14px;
    line-height: 24px;
  }
}

.home-carousel{
  max-height: 600px;
  background-color: #1C4380;

  .carousel, .carousel-inner {
    width:100%;
    max-height:600px;
    margin: auto;
  }

  .custom-slider-item{
    min-height:300px;
    background-color: #1C4380;
  }

  .mdm-slider-item
  {
    height: 300px;
    min-height: auto;
  }

  .home-carousel-slide-content{
    position: absolute;
    left: 200px;
    top: 50%;
    max-width: 40%;
    transform: translateY(-50%);
    /*
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    */

    .home-carousel-slide-container{
      /*
      max-width: 1300px;
      width: 100%;
      margin: auto;
      */
    }

    h1, p{
      color: rgba(255, 255, 255, 0.9);
    }

    .slide-primary-text{
      font-size: 3.5rem;
      font-weight: 500;
      line-height: 4rem;
    }

    .slide-primary-text2{
      font-size: 65px;
      font-weight: 200;
      line-height: 70px;
    }

    .slide-secondary-text{
      font-size: 24px;
      line-height: 30px;
      color: rgba(255, 255, 255, 0.9);
      letter-spacing: 0.06em;
      font-weight: 200;
      display: block;
    }

    .slide-summary{
      font-size: 22px;
      line-height: 29px;
      margin-bottom: 30px;
      margin-top: 30px;
      display: block;
    }

    .slide-cta-buttons{
      z-index: 2;
      margin-top: 46px;
      display: block;
    }
  }

  .home-carousel-slide-content-prefx
  {
      max-width:60%;
  }

  .home-carousel-slide-content-prefx{
    p.slide-summary{
      margin-top:10px;
    }
  }

  .mdm-slider-item {
    .home-carousel-slide-content-prefx{
      p.slide-summary{
        margin-bottom:0px;
      }
    }
  }
  
  .heading-slider-style{
      color:#21bdbe;
  }
}

.event-reg-home-carousel{
    .carousel-item,.carousel-inner{
        position: unset;
    }
    .event-post-date {
      position: absolute;
      width: 100%;
      background-color: #1c4280;
      bottom: 0px;
      left: 0;
      color: #fff;
      height: auto;
      display: flex;
      align-items: center;

      .custom-page-content
      {
        padding: 0 0;
        p{
            color: #fff;
            font-size: 1.3rem;
            margin-bottom: 0;
        }

        form {
                  background-color: #fff;
                  padding: 20px;
        }
        .event-content-blockplacer-form {
              padding: 20px;
              background-color: #f5f5f5;
              border-radius: 10px;
          }

      }
    }
}

.upcoming_event_card{
  position: relative;
  background-color: #1C4380;
  background: url('/assets/images/upcoming_event_card_bg.png') #1C4380;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  min-height: 400px;

  .card-header{
    border-bottom: 0px;
    h1{
      color: rgba(255, 255, 255, 0.9);
      padding: 16px 0px;
      font-size: 30px;
    }
  }

  .card-body{
    ul{
      list-style: none;
      padding-bottom: 100px;
      & > li{
        margin-bottom: 56px;

        div,h4,p{
          color: rgba(255, 255, 255, 0.9);
        }

        &>div{
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 8px;
          text-transform: uppercase;
        }

        &>h4{
          font-size: 24px;
          line-height: 32px;
        }

        &>p{
          font-size: 14px;
          line-height: 24px;
          font-weight: 300;
          margin-bottom: 2em;

        }
      }
    }
  }
}

.home__author_grid{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  .grid-item{
    width: 20%;
    height: 250px;
    position: relative;
    overflow: hidden;

    &:hover{
      .author-name{
        opacity: 1;
      }
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .author-name{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background:linear-gradient(rgba(0,0,0,0.0), #1A386A, #1A386A);
      color: #FFFFFF;
      text-align: center;
      font-weight: 200;
      font-size: 14px;
      padding: 8px 4px;
      opacity: 0;
      transition: 300ms;

      h5{
        color: #FFFFFF;
        font-size: 24px;
        line-height: 28px;
        font-weight: 300;
        text-align: center;
      }

      .subtext{
        font-size: 16px;
        line-height: 23px;
        color: #DCDCDC;
        font-weight: 200;
        text-align: center;
      }
    }
  }
}

.custom-page-head{
  max-width: 800px;
  width: 100%;
  padding: 64px 16px;
  margin: auto;

  h1, a, p{
    color: rgba(255, 255, 255, 0.9);
  }
}

.custom-page-content{
  max-width: 800px;
  width: 100%;
  padding: 64px 16px;
  margin: auto;

  p{
    strong{
      font-weight: 500;
    }
  }
}

.custom-page-content-prefix
{
  max-width:70%;
  .content-blockplacer
  {
      max-width: 85%;
  }

  .content-blockplacer-mw-90{
    max-width: 90%;
  }

  .event__speaker_container_wapper {
      background-color: #f7ebdf;
      padding: 25px 20px;
      border-radius: 10px;
      margin-bottom: 25px;
  }
  .event__speaker_container_wapper 
  h5{
      text-align:center;
      font-size: 1rem;
  }
  .event__speaker_container_wapper 
  p {
      text-align: center;
      font-size: .8rem;
      line-height: 1rem;
  }

  .event__joinuswap_container p {
      color: #fff;
      font-size: .9rem;
      line-height: 1.3rem;
  }
  .event__joinuswap_container {
      background-color: #1c4280;
      color: #fff;
      padding: 15px;
      border-radius: 10px;
  }
}

.custom-page-content {
  .content-blockplacer-form{
    .form-control-lg{ font-size:1rem; }
      
     .title-limit{
        text-align: right;
        font-size: 12px;
        margin-top: -12px;     
     }

    .css-g1d714-ValueContainer {
        min-height: auto;
        height: 45px;
    }
    .custom-select-container .custom-select__control {
        min-height: auto;
        height: 45px;
    }
    .css-1wa3eu0-placeholder {
        font-size: .9rem;
    }
    .css-b8ldur-Input {
        height: 40px;
    }
    .custom-control{
      z-index: -1;
    }
    .css-1uccc91-singleValue {
        top: 54%;
        font-size: .9rem;
    }
    .tos-container {
        position: relative;
        z-index: 0;
    }
    .custom-select_menu.css-26l3qy-menu
    {
      background-color:#fff;
      z-index: 999;
      position: relative;
    }

    .event-content-blockplacer-form {
        background-color: #f4f5f4;
        padding: 35px 25px;

        .custom-page-content .content-blockplacer-form .event-content-blockplacer-form {
            background-color: #f4f5f4;
            padding: 35px 25px;
        }
        .form-footer-wap {
            padding-top: 20px;
            margin-bottom: -20px;
        }
    }
    .event-content-blockplacer-form form {
        background-color: #fff;
        padding: 20px;
    }

  }
}

.content-blockplacer-form .event-content-blockplacer-form {
    background-color: #f4f5f4;
    padding: 35px 25px;
}

.content-blockplacer-form .event-content-blockplacer-form form {
    background-color: #fff;
    padding: 20px;
}

.content-blockplacer-form .event-content-blockplacer-form .form-footer-wap {
    padding-top: 20px;
    margin-bottom: -20px;
}

.content-blockplacer-form .event-content-blockplacer-form .form-footer-wap p {
    font-size: .9rem;
    text-align:center
}

.story__card{
  .card-img-top{
    height: 200px;
    background-color: rgba(0, 0, 0, 0.2);
    object-fit: cover;
  }
}

@media screen and (max-width: 1280px){
  .custom-page-content p strong {
      font-weight: 500;
      line-height: 2rem;
  }

  .custom-page-content-prefix .content-blockplacer {
      max-width: 100%;
      margin-bottom: 20px;
  }

  .custom-page-content-prefix {
      max-width: 90%;
  }

  .home-carousel{
    max-height: 650px;
    background-color: rgba(0,0,0,.1);
    .carousel, .carousel-inner {
      width:100%;
      max-height:650px;
      margin: auto;
    }
  
    .home-carousel-slide-content{
      
      .slide-primary-text{
        font-size: 45px;
        font-weight: 500;
        line-height: 52px;
      }
  
      .slide-primary-text2{
        font-size: 45px;
        font-weight: 200;
        line-height: 52px;
      }
  
      .slide-secondary-text{
        font-size: 24px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.9);
        letter-spacing: 0.06em;
        font-weight: 200;
        display: block;
      }
  
      .slide-summary{
        font-size: 22px;
        line-height: 29px;
        margin-bottom: 30px;
        margin-top: 30px;
        display: block;
      }
  
      .slide-cta-buttons{
        z-index: 2;
        margin-top: 46px;
        display: block;
      }
    }
  }

  .onboard-form-container > .progress{
    margin-top: 80px;
  }

  .btn-checkbox-group div{
    margin: 4px 4px;
  }
  .btn-checkbox-group div > label{
    padding: 0.5rem 0.7rem;
  }
}

@media screen and (max-width: 960px) {
    .it-navbar-auth{
        display: none !important;
    }
    
    .it-navbar-center{
        position: absolute;
        top: 80px;
        min-height: 90vh;
        width: 100%;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;

        .menu{
            display: flex;
            flex-direction: column;
            width: 100%;

            .dropdown-menu{
                position: relative;
            }
        }
    }

    .it-navbar-center.active{
        background-color: #FFFFFF;
        left: 0;
        opacity: 1;
        transition: all 0.6s ease;
        z-index: 1;
    }

    .it-footer{
      .footer-logo {
        img{
          height: 40px;
          margin: 16px;
        }
      }
    
      .it-footer--menu{
        margin-top: 16px;
        margin-bottom: 16px;
        & > li {
          text-align: center;
          a{
            font-weight: 300;
            text-decoration: none;
            font-size: 12px;
            color: #333333;
          }
        }
      }
    }

    .home__author_grid{
      .grid-item{
        width: 50%;
      }
    }
  
    .it-write-header-right{
      .user-menu-box{
        display: none;
      }
    }
}


.event-container{
  .event-backdrop-image{
    position: relative;
    min-height: 100px;

    & > .bg-image{
      width: 100%;
      height: auto;
      max-height: 500px;
      object-fit: cover;
    }

    & > .event__header{
      position: absolute;
      left: 10%;
      bottom: 10%;

      & > h1{
        color: rgba(255,255,255,0.9);
      }

      & > .btn-join-event{
        /* padding: 12px 60px; */
      }
    }
  }

  .btn-join-event{
    /* padding: 12px 60px; */
    margin-top: 16px;
  }
  .event-body{
    
    .event-info{
      .event__info-block{
        background-color: #EBEBEB;
        margin: 10px 0px;
        padding: 16px;
        min-height: 150px;
        height: 100%;

        & > label{
          font-size: 0.7rem;
          color: #EF752F;
          font-weight: 300;
        }

        & > .btn-join-event{
          /* padding: 8px 40px; */
        }

        .event__dates{
          .event__day{
            font-size: 2.8em;
            font-weight: 100;
          }

          .event__month_year{
            margin-left: 16px;

            & > div{
              text-transform: uppercase;
              font-size: 0.8rem;
              font-weight: 100;
              font-weight: 300;
              letter-spacing: .2rem;
            }

            & > div:last-child{
              text-transform: capitalize;
              font-weight: 100;
              margin-top: 5px;
              letter-spacing: normal;
            }
          }
        }
      }
    }
  }

  .event__speaker_container{
    max-width: 800px;
    width: 100%;
    margin: auto;

    .speaker_image{
      height: 300px;

      & > img{
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .col-md-4{
      h5{
        font-size: 1.0rem;
      }
      p{
        font-size: 0.8rem;
      }
    }
  }

  .event__agenda_container{
    max-width: 800px;
    width: 100%;
    margin: auto;
    margin-top: 38px;
    .row{
      margin-bottom: 18px;
    }
    .agenda__time{
      background-color: #F6F6F6;
      font-weight: 300;
      color: #666;
      padding: 4px;
    }

    .agenda__title{
      font-weight: 500;
      font-size: 18px;
    }

    p{
      font-size: 0.8rem;
      color: #999999;
    }
  }
}

.search__header{
  margin-bottom: 2em;

  & > p{
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  & > h1{
    line-height: 37px;
  }
}

/*  NPROGRESS */

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  height: 3px;
  background: #EF752F;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
}

.site-loader{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-loader__lite{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.icon-btn{
  font-size: 35px;
}

.icon-btn-active{
  fill: #EF752F;
}

.it-write-header{
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #E4E4E4;
  .it-write-header-container{
    max-width: 1200px;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &>div{
      flex: 1;
      display: flex;
      height: 100%;
      align-items: center;
    }

    &>div:last-child{
      justify-content: flex-end;
    }
  }

  .btn-it-link{
    line-height: 37px;
    text-transform: uppercase;
    color: #EF752F;
    margin: 0px;
    font-weight: 300;
    background-color: transparent;
    border: 0px;
    outline: transparent;

    &:disabled{
      opacity: 0.6;
    }
  }

  .btn-it-primary{
    margin: 0px;
    outline: transparent;
  }
}

.write-post-container{
  margin-top: 38px;

  .content-form{
    h4{
      font-weight: 300;
      font-size: 22px;
      line-height: 24px;
      color: #484848;
    }
    p{
      font-size: 15px;
      line-height: 15px;
      font-weight: 200;
      color: #575555;
    }
  }

  .step-label{
    color: #898888;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 18px;
    font-weight: 200;
  }

  .post-progress-container{
    margin-top: 100px;
  
    .progress{
      height: 5px;
    }
  
    .post-bottom-nav{
      margin-top: 26px;
    }
  
    .btn-it-link{
      line-height: 37px;
      text-transform: uppercase;
      color: #EF752F;
      margin: 0px;
      font-weight: 300;
      background-color: transparent;
      border: 0px;
      outline: transparent;
  
      &:disabled{
        opacity: 0.6;
      }
    }
  }
  
  .post-type-container{
    display: flex;
    align-items: center;
    justify-content: center;

    .rdo-group{
      display: inline-block;
      margin-top: 46px;

      & > input{
        position: absolute;
        left: -20px;
        right: -20px;
      }

      & > label{
        width: 180px;
        height: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #FAFAFA;
        border-radius: 50%;
        margin-right: 16px;
        cursor: pointer;
        border: 2px solid #FAFAFA;

        &:hover{
          border: 2px solid var(--primary);
        }

        & > img{
          margin-bottom: 16px;
        }

        & > h4{
          font-weight: 300;
          font-size: 14px;
          line-height: 14px;
        }
      }

      & > input:checked ~ label{
        border: 2px solid var(--primary);
      }
    }
  }

  .post-title-container{
    & > textarea{
      width: 100%;
      padding: 15px 20px;
      background-color: #F5F5F5;
      border: 2px solid #F5F5F5;
      font-size: 28px;
      font-weight: 300;
      color: #858484;

      outline: none;
    }

    & > .title-limit{
      text-align: right;
    }
  }

  .post-category-tag-and-container{
    .custom-select-container {
      .custom-select__control{
        border: 0px solid red;
        border-radius: 0px;
        background-color: #F5F5F5;
      }
      .custom-select__multi-value{
        background-color: #DEDEDE;
        color: #575555;
        padding: 5px;
      }

      .custom-select__multi-value .custom-select__multi-value__label, .custom-select__multi-value .custom-select__multi-value__remove{
        color: #575555;
        font-size: 15px;
        line-height: 17px;
      }
    }
  }
}

.rrt-error{
 
  .rrt-title, .rrt-text{
    color: white;
  }
}

.container-dropzone {
  background-color: #F5F5F5;
  padding: 16px;
}

.container-dropzone p {
  color: red;
  text-align: center;
}

.drop-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
  min-height: 400px;
  border: 2px dashed #909090;

  & > img{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: transparent;
  }
}

.upload-icon {
  margin: 0 auto;
  font-size: 78px;

  & > svg{
    fill: #454545;
  }
}

.drop-message {
  text-align: center;
  color: #454545;
  font-size: 20px;
}

.dzu-dropzone{
  overflow: visible;
  border: 0px solid;
}

.add-content-actions{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFFFFF;
  border-top: 1px solid #E4E4E4;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > .btn-it-primary.outlined{
    margin: 10px 28px;
    line-height: 50px;
    height: 50px;
    padding-left: 40px;
    padding-right: 40px;
    outline: none;
  }
}

.content-blocks-container{
  margin-bottom: 140px;

  .content-block{
    input, textarea{
      width: 100%;
      background-color: #F5F5F5;
      border: 2px solid #F5F5F5;
      padding: 10px 16px;
      outline-color: var(--primary);
    }
  }
}

.ck-editor__editable:not(.ck-editor__nested-editable) { 
  min-height: 200px;
}

.container-accounts{
    margin-bottom: 15px;
    .left-box{
      border-right: 1px solid #e1e1e1;
      &>.left-menu{
        list-style: none;
        position: sticky;
        top:130px;
        &>li{
          padding: 0px;

          &>a{
            display: block;
            text-align: right;
            padding: 8px 4px;
            font-size: 16px;
            font-weight: 300;
            text-decoration: none;
            transition: 300ms all;
            line-height: 20px;
            &:hover{
              color: #EE752F;
              font-size: 16px;
            }
          }
          &>a.active{
            font-size: 16px;
            color: #EE752F;
          }
        }
      }
  }

  .right-box{
    padding-top: 16px;
    padding-bottom: 16px;
    button.btn-primary{
      font-weight: 300;
      background-color: #EE752F;
      &:active{
        background-color: #EE752F;
      }
    }
  }
}

@include media-breakpoint-only(xs) {
  h1{
    font-size: 22px;
    line-height: 22px;
  }

  p{
    font-size: 14px;
    line-height: 16px;
  }

  /* HOME PAGE */
  .upcoming_event_card{
    margin: 0px -30px;
  }

  .home__section_5{
    p{
      line-height: 30px;
    }
  }

  .home__section_8{
    .left-box, .right-box{
      text-align: center;
    }
    .right-box{
      margin-top: 84px;
    }
    .v-divider{
      display: none;
    }
  }

  /* AUTH PAGES*/
  .auth-page{
    .left-box{
      min-height: 550px;
      img{
        width: 100%;
        max-width: 350px;
      }

      .quote-pic{
        left: 50% !important;
        top: 40px !important;
        transform: translateX(-50%);
        width: 50%;
        max-width: 400px;
      }
    }
    .right-box{
      text-align: center;

      .form-group{
        text-align: left;
      }
    }
  }

  .onboard-page{
    .left-box{
      min-height: 500px;
      img{
        width: 100%;
        max-width: 400px;
      }
      .quote-pic{
        top: 40px !important;
        width: 80%;
        max-width: 400px;
      }
    }
    .right-box{
      text-align: center;
      padding-top: 84px;
      .progress{
        margin-top: 46px;
        margin-bottom: 46px;
      }
      .form-group{
        text-align: left;
      }
    }
  }
}

@include media-breakpoint-only(sm){
  h1{
    font-size: 22px;
    line-height: 22px;
  }

  p{
    font-size: 14px;
    line-height: 16px;
  }

  /* HOME PAGE */
  .upcoming_event_card{
    margin: 0px -30px;
  }

  .home__section_5{
    p{
      line-height: 30px;
    }
  }

  .home__section_8{
    .left-box, .right-box{
      text-align: center;
    }
    .right-box{
      margin-top: 84px;
    }
    .v-divider{
      display: none;
    }
  }

  /* AUTH PAGES*/
  .auth-page{
    .left-box{
      min-height: 250px;
      img{
        max-width: 200px;
      }

      .quote-pic{
        right: 10px !important;
        top: 10px !important;
        max-width: 250px;
      }
    }
    .right-box{
      text-align: center;
    }
  }

  .onboard-page{
    .left-box{
      min-height: 500px;
      img{
        max-width: 400px;
      }
      .quote-pic{
        top: 40px !important;
        max-width: 350px;
      }
    }
    .right-box{
      text-align: center;

      .progress{
        margin-top: 46px;
        margin-bottom: 46px;
      }
    }
  }
}

@include media-breakpoint-only(md){
  h1{
    font-size: 22px;
    line-height: 22px;
  }

  p{
    font-size: 14px;
    line-height: 16px;
  }

 

  /* HOME PAGE */
  .upcoming_event_card{
    margin: 0px -30px;
  }

  .home__section_5{
    p{
      line-height: 30px;
    }
  }
  

  /* AUTH PAGES*/
  .auth-page{
    .left-box{
      min-height: 250px;
      img{
        max-width: 200px;
      }

      .quote-pic{
        right: 10px !important;
        top: 10px !important;
        max-width: 250px;
      }
    }
    .right-box{
      text-align: center;
    }
  }

  .onboard-page{
    .btn-it-link{

    }
    .left-box{
      min-height: 500px;
      img{
        max-width: 400px;
      }
      .quote-pic{
        top: 40px !important;
        max-width: 350px;
      }
    }
    .right-box{
      text-align: center;
    }
  }
}

@include media-breakpoint-only(lg){
  /* AUTH PAGES*/
  .auth-page{
    .left-box{
      min-height: 250px;
      img{
        max-width: 200px;
      }

      .quote-pic{
        right: 10px !important;
        top: 10px !important;
        max-width: 250px;
      }
    }
    .right-box{
      text-align: center;
    }
  }
}

@include media-breakpoint-only(xl){
  /* AUTH PAGES*/
  .auth-page{
    .left-box{
      min-height: 250px;
      img{
        max-width: 400px;
      }

      .quote-pic{
        right: 50px !important;
        top: 50px !important;
      }
    }
    .right-box{
      text-align: center;
      .form-group{
        text-align: left;
      }
    }
  }
  .auth-page .left-box img.customMaxWidth{
    max-width: 85%;
  } 
}

@include media-breakpoint-down(md){

  .btn-it-primary, .btn-it-secondary{
    line-height: 35px;
    height: 35px;
    font-size: 14px;
  }

  .it-navbar{
    display: none;
  }

  .it-mobile-navbar{
    display: flex;
  }

  .home-carousel{
    .custom-slider-item{
      height: 600px;
      display: flex;
      flex-direction: column-reverse;

      .home-carousel-slide-content{
        position: relative;
        transform: none;
        max-width: 85%;
        left: 0px;
        top: 0px;
        margin: auto;
      }
    }
    .home-carousel-slide-content{
      h1{
        line-height: 22px;
        font-size: 34px;
        font-weight: 500;
      }

      .slide-primary-text{
        font-size: 45px;
        font-weight: 500;
        line-height: 52px;
      }
  
      .slide-primary-text2{
        font-size: 45px;
        font-weight: 200;
        line-height: 52px;
      }
  
      .slide-secondary-text{
        font-size: 12px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.9);
        letter-spacing: 0.06em;
        font-weight: 300;
        display: block;
      }
  
      .slide-summary{
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 20px;
        margin-top: 20px;
        display: block;
        text-align: justify;
      }
  
      .slide-cta-buttons{
        z-index: 2;
        margin-top: 46px;
        display: block;
      }
    }
  }

  .home__section_4{
    
    .home__author_grid{
      .grid-item{
        height: auto;
        margin-bottom: 28px;
        padding: 0px 8px;

        img{
          max-height: 400px;
          height: auto;
          width: 100%;
          margin: auto;
        }

        a{
          text-decoration: none;
        }
        
        .author-name{
          opacity: 1;
          position: relative;
          background: transparent;
          color: #000;
          
  
          h5, div{
            color: #575555 !important;
            font-size: 12px !important;
            text-align: left;
            margin-bottom: 0px;
          }
  
          h5{
            font-size: 16px !important;
            line-height: 24px !important;
          }
        }
      }
    }
  }

  .home__section_7{
    .left-box{
      height: 400px !important;
      img{
        height: auto !important;
        width: 100%;
        left: 50% !important;
        transform: translateX(-50%);
      }
    }
    .right-box{
      min-height: 300px;
    }
  }

  .home__section_8{
    .left-box, .right-box{
      text-align: left;
    }
    .right-box{
      margin-top: 84px;
    }
    .v-divider{
      display: none;
    }
  }

  .it-footer{
    .footer-logo > img{
      width: 80%;
      max-width: 250px;
      height: auto;
    }

    .footer-logo.powered-by-logo > img{
      max-width: 180px;
    }

    .it-footer--menu > li{
      text-align: left;
    }
  }

  /* POST DETAIL PAGE */

  .post-container{
    .post-tags{
      font-size: 12px;
      .right-box{
        text-align: left !important;
        margin-left: 5px;
        margin-top: 20px;
      }
    }
    .post__title{
      line-height: 35px;
      font-size: 28px;
      font-weight: 500;
    }
    .user-and-actions-container{
      margin-top: 10px;
      .right-box{
        .share-btn{
          margin-right: 8px;
          span{
            font-weight: 300;
            color: #EE752F;
          }
          svg{
            font-size: 28px !important;
          }
        }
      }
    }

    .post-featured-image{
      min-height: 300px;
    }

    .post__next-last-bottom-nav .row > div{
      height: 250px;
      h3{
        font-size: 14px;
      }
      p{
        font-size: 12px !important;
        svg{
          font-size: 24px !important;
        }
      }
    }
  }
  
  /* WRITE POST*/

  .it-write-header{
    height: 60px;
    padding: 0px 16px;

    .navbar-logo{
      position: relative;
      transform: none;
      top: auto;
      img{
        height: 42px;
      }
    }

    .btn-it-link, .btn-it-primary{
      font-size: 12px;
      font-weight: 500;
    }

    .btn-it-primary{
      padding: 0px 8px;
    }

    .it-write-header-left{
      .btn-it-link{
        margin-left: 8px !important;
      }
    }

    .it-write-header-right{
      .btn-it-link{
        margin-right: 8px !important;
      }
    }
  }

  .write-post-container{
    svg{
      cursor: pointer;
    }

    textarea{
      font-size: 22px !important;
    }

    .btn-it-link, .btn-it-primary{
      outline: none;
    }

    .btn-it-primary{
      padding: 0px 28px;
    }

    .step-label{
      font-weight: 500;
    }

    .post-type-container{
      flex-wrap: wrap;
      justify-content: space-evenly;

      .rdo-group{
        label{
          width: 120px;
          height: 120px;

          img{
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .post-progress-container{
      margin-top: 46px;
    }

    .dzu-dropzone{
      min-height: 250px !important;

      .drop-container{
        min-height: 250px;
      }
    }

    .add-content-actions{
      height: 120px;
      flex-wrap: wrap;
      .btn-it-primary{
        margin: 3px 6px;
        width: 160px;
        padding: 0px 10px;
        height: 40px;
        line-height: 40px;
      }
    }

    .content-blocks-container{
      .content-block{
        .content-block-actions > div{
          flex-direction: row !important;
        }
      }
    }
  }

  .onboard-page, .auth-page{
    .right-box{
      .form-group{
        text-align: left;
      }
    }

    .nav-buttons{
      margin-top: 28px;
    }
  }

  .table-posts{
    width: 100%;
    td, th{
      padding: 16px 0px;
      border-bottom: 0.5px solid #C4C4C4;
    }
    th{
      font-size: 10px !important;
    }
    td{
      font-size: 12px !important;
      line-height: 20px;
      font-weight: 500;
      padding: 26px 0px;
    }
  }

  .container-accounts{
    margin-top: 30px;
    .left-box{
      border-right: 0px;
      .left-menu{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: #F2DFD4;
        border-radius: 4px;

        &>li{
          display: inline-block;
          &>a{
            text-align: center;
            font-size: 12px;
            &:hover{
              font-size: 12px;
              font-weight: 500;
            }
            &.active{
              font-size: 12px;
              border-bottom: 2px solid;
              font-weight: 500;
            }
          }
          
        }
      }
    }
    .right-box{
      h5{
        font-size: 14px;
      }

      .btn.btn-primary{
        display: block;
        width: 100%;
        float: none;
      }
    }
  }
}

.it-breadcrumb{
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2em;
  font-weight: 300;
  margin-bottom: 16px;
}

.contact-page{
  margin-top: 80px;
  margin-bottom: 80px;
  .heading{
    p{
      font-size: 18px;
      line-height: 32px;
    }
    h1{
      font-size: 52px;
      line-height: 65px;
    }
  }

  .inner-box{
    padding: 28px 38px;
    background-color: #EBEBEB;
    margin-bottom: 28px;
    max-width: 500px;

    & > h3{
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.01em;
      font-weight: 500;
    }

    & > p{
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.01em;
    }

    .social-links > a{
      margin-right: 16px;
      text-decoration: none;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .contact-page{
    .custom-control-label{
      cursor: pointer;
    }
  }
}

.quote-pic{
  max-width: 450px;
}

.onboard-page{
  .btn-it-link{
    color: var(--primary);
    background: transparent;
    border: 0px transparent;
    padding: 8px 20px;
    font-weight: 300;
    font-size: 1rem; 
  }
}

.tos-container{
  
  p{
    cursor: pointer;
    font-weight: 300;
  }
  a{
    color: var(--primary);
  }
}

.input-group-prepend{
  .input-group-text{
    svg{
      font-size: 24px;
    }
  }
}

.main-event-content{
  margin-top: 84px;
  h3{
    text-align: center;
  }
  .custom-page-content{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* MY CONTENT PAGE */

.my-content-page{
  .heading h1 {
    font-size: 52px;
    line-height: 65px;
  }

  h5{
    font-size: 14px;
    line-height: 14px;
  }

  .row{
    margin-bottom: 16px;
  }

  .count-card{
    background-color: #FFF2EA;
    padding: 24px 28px;
    height: 110px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;

    .count-card--title{
      font-size: 10px;
      font-weight: 300;
      line-height: 14px;
      text-transform: uppercase;
      margin-bottom: 16px;
      letter-spacing: 0.11em;
    }

    .count-card--count{
      font-size: 20px;
      font-weight: 500;
      line-height: 14px;
      text-transform: uppercase;
    }
  }

  .other-post-activities{
    margin-bottom: 54px;
    .count-card{
      .count-card--title{
        color: #EE752F;
      }
    }
  }

  .my-post-activities{
    margin-bottom: 84px;
    .count-card{
      background-color: #EFFFFF;
      .count-card--title{
        color: #14898A;
      }
    }
  }

  .table-posts{
    width: 100%;
    td, th{
      padding: 16px 0px;
      border-bottom: 0.5px solid #C4C4C4;
    }
    th{
      font-size: 15px;
      font-weight: 300;
    }
    td{
      font-size: 18px;
      line-height: 20px;
      font-weight: 500;
      padding: 26px 0px;
    }
  }

  .nav-paginate{
    margin-top: 16px;
    
    a{
      border: none;
      text-align: left;
      display: inline;
      color: #455454;

      & > svg{
        fill: #455454;
      }
    }

    .next{
      text-align: right;
    }
  }
}

.it-navbar-auth{
  .user-logo{
    width: 38px;
    height: 38px;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    border: 2px solid #EF752F;
    text-align: center;
    line-height: 38px;
    font-weight: 500;
    background-color: #FFFFFF;
    color: #EF752F;
    font-size: 16px;
  }
    
    .navlogwapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.it-write-header-right{
  .user-logo{
    width: 38px;
    height: 38px;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    border: 2px solid #EF752F;
    text-align: center;
    line-height: 38px;
    font-weight: 500;
    background-color: #FFFFFF;
    color: #EF752F;
    font-size: 16px;
  }
  .user-menu-box{
    margin-left: 8px;
    margin-right: 8px;
  }
}

.card-title-link{
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  text-decoration: none;

  &:link{
    text-decoration: none;
  }
}

.authors-list{
  list-style: none;
  padding: 0px;
  margin: 0px;
  margin-bottom: 64px;

  & > li{
    padding: 12px 0px;
    display: flex;
    border-bottom: 1px solid #E1E1E1;

    &:last-child{
      border-bottom: 0px;
    }
    
    img{
      border-radius: 50%;
      width: 64px;
      height: 64px;
      overflow: hidden;
      background-color: #909090;
    }

    b{
      font-size: 22px;
      margin-left: 28px;
      transition: all 300ms;
    }

    a{
      text-decoration: none;
      width: 100%;
      svg{
        transition: all 300ms;
      }

      &:hover > b > svg{  
        fill: #007bff;
        margin-left: 10px;
      }

      &:hover > b{
        color: #007bff;
      }
    }
  }
}

.nav-paginate{
  margin-bottom: 64px;
  a{
    font-size: 14px;
    font-weight: 500;
    border-radius: 50px;
    border: 2px solid #EF752F;
    padding: 0px 16px;
    line-height: 37px;
    height: 37px;
    color: #EF752F;
    letter-spacing: 0.03em;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    width: 100%;
    text-align: center;
    & > svg{
      width: 2rem;
      height: 2rem;
      fill: #EF752F;
    }
  }
}

.author-page{
  .author-card{
    display: flex;
    border-bottom: 1px solid #E1E1E1;
    padding: 8px 0px;

    .avatar-inner{
      width: 150px;
      height: 180px;
      overflow: hidden;
      background-color: #909090;
      border-radius: 8px;
      position: relative;

      .avatar{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .badge-level{
        font-weight: 300;
        font-size: 10px;
        text-transform: uppercase;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        margin-bottom: 0px;
        text-transform: uppercase;
        color: #FFFFFF;
        height: 24px;
        line-height: 24px;
        & > img{
          width: 48px;
          height: 48px;
          margin-top: -28px;
          margin-left: 8px;
          margin-right: 4px;
        }
      }
    }
    
    .breadcrum{
      font-size: 14px;
      line-height: 20px;
      font-weight: 200;
      text-transform: uppercase;
      letter-spacing: 0.2em;
    }
    .right-box{
      padding: 0px 26px;
      flex: 1;
  
      .author-name{
        font-size: 52px;
        line-height: 65px;
        font-weight: 500;
        color: #575555;
      }
  
      .author-job-title{
        font-size: 20px;
        line-height: 22px;
        font-weight: 200;
        color: #313131;
      }

      .author-badges{
        margin-bottom: 8px;
        .badge-img{
          width: 68px;
          height: auto;
          margin-right: 8px;
        }

        .badge-point{
          font-size: 22px;
          font-weight: 100;
          color: #1B4380;
        }
      }
  
      .author-bio{
        word-wrap: break-word;
        overflow-wrap: break-word;
        font-size: 18px;
        font-size: 200;
        letter-spacing: 0.01em;
        color: #838383;
        white-space: pre-wrap;
      }
      .author-social-links{
        margin-bottom: 16px;
        & > a{
          margin-right: 16px;
          svg{
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }

  .stories-by-label{
    color: #454545;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    padding: 32px 0px;
  }
}

.user-menu-box, .post-menu-box{
  position: relative;
  cursor: pointer;
  .user-menu-container, .post-menu-container{
    display: none;
    position: absolute;
    background-color: rgb(255, 255, 255);
    max-width: 240px;
    right: 0;
    top: 120%;
    box-shadow: rgb(230, 230, 230) 0px 1px 4px;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 700;
    
    
    & > .user-menu{
      list-style: none;
      margin-bottom: 0px;
  
      & > li{
        & > a{
          display: block;
          padding: 8px 20px;
          color: #666666;
          font-weight: 200;
          text-decoration: none;
  
          &:hover{
            color: #000000;
          }
        }

        &:last-child{
          margin-bottom: 16px;
        }
      }

      & >.with-divider{
        border-bottom: 1px solid #e6e6e6;
      }

      & > .user-details{
        display: flex;
        align-items: center;
        padding: 8px 20px;
        width: 200px;
        margin-bottom: 16px;
        & > h5{
          font-size: 12px;
          margin-bottom: 0px;
          font-weight: 300;
          margin-left: 8px;
        }
      }

      & > .user-points-item{
        &>a{
          padding: 0px !important;
        }
        .user-points{
          display: flex;
          padding: 8px 20px;
          align-items: center;

          .badge-icon{
            width: 42px;
            height: 42px;
          }

          .point-progress-bar{
            width: 100%;
            margin-left: 4px;
            .point-labels{
              font-size: 12px;
              display: flex;
              justify-content: space-between;
              font-weight: 200;
            }

            .progress-bar{
              height: 8px;
              border-radius: 8px;
              background-color: #E1E1E1;
              position: relative;

              .active-progres{
                background-color: #595c97;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                border-radius: 8px;
              }
            }
          }
        }
      }
    }

    & > .top-pointer{
      clip: rect(0px, 18px, 14px, -4px);
      position: absolute;
      top: -14px;
      right: 8px;
      &::after{
        content: '';
        box-shadow: rgb(117, 117, 117) -1px -1px 1px -1px;
        transform: rotate(45deg) translate(6px, 6px);
        background: rgb(255, 255, 255);
        height: 14px;
        width: 14px;
        display: block;
      }
    }
  }
  
  .post-menu-container > .user-menu{
    & > li{
      width: 120px;
      &:last-child{
        margin-bottom: 8px;
      }
      &:first-child{
        margin-top: 8px;
      }
    }
  }

  & > .active{
    display: block;
  }
}

.share-modal{
  background: rgba(0,0,0,0.6);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  display: none;

  & > .share-modal-container{
    max-width: 450px;
    background: #FFFFFF;
    width: 100%;
    display: block;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    
    .share-button-strips{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 16px;
      width: 100%;

      & > button{
        margin: 2px;
      }
    }

    .copy-link-strip{
      margin: 0px 16px 16px 16px;

      & > input{
        border-bottom: 0px;
        border-radius: 0.25rem 0.25rem 0px 0px;
        font-weight: 300;
      }
      & > button{
        border-bottom: 0px;
        border-radius: 0px 0px 0.25rem 0.25rem;
        font-weight: 300;
      }
    }

    & > .card-header{
      height: 50px;
      h5{
        float: left;
        font-weight: 300;
      }
      .close{
        float: right;
        cursor: pointer;
        fill: #000;
        color: #000;
      }
    }
  }
}

.comment__row{
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 16px;
  padding: 16px 0px;
  &:last-child{
    border-bottom: 0px;
  }
  & > .comment_header{
    display: flex;
    align-items: center;
    font-weight: 200;
    margin-bottom: 8px;

    .comment__user {
      font-size: 16px;
      line-height: 20px;
      margin-right: 8px;
      font-weight: 300;
    }

    .comment__date {
      font-size: 12px;
      line-height: 20px;
      margin-left: 8px;
      font-weight: 200;
    }
  }
  & > .comment__message{
    line-height: 20px;
    font-size: 14px;
    white-space: pre-wrap;
    font-weight: 200;
    letter-spacing: 0.02rem;
  }
}
.comment-form{
  textarea{
    height: 150px;
    font-size: 18px;
  }
}

.user-image-upload{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 16px;
  &>div{
    &:first-child{
      .user-logo{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #F1F1F1;
        object-fit: cover;
        border: 2px solid #EF752F;
        line-height: 100px;
        text-align: center;
        font-weight: 500;
        font-size: 35px;
        color: #EF752F;
        margin-right: 16px;
      }
    }
    &.img-input-field{
      
      overflow: hidden;
      position: relative;
      
      label{
        border-radius: 8px;
        border: 2px solid #EF752F;
        padding: 8px 8px;
        cursor: pointer;
        font-weight: 300;
        color: #EF752F;

        svg{
          fill: #EF752F;
          width: 16px;
          height: 16px;
          margin-right: 16px;
        }
      }

      input[type=file]{
        position: absolute;
        top: -1px;
        left: -1px;
        width: 1px;
        height: 1px;
        visibility: hidden;
      }
    }
  }
}

.search-input-container{
  border: 0px solid #000;
  height: 60px;
  border: 1px solid #e1e1e1;
  margin: 20px 0px;
  overflow: hidden;

  & > form {
    display: flex;
    align-items: center;
    height: 100%;
    & > .search-input{
      width: 100%;
      height: 100%;
      font-size: 25px;
      border: 0px solid white;
      outline: white;
      padding: 0px 16px;
    }
  
    & > .search-button{
      border: 0px solid #EF752F;
      color: #000;
      font-weight: 500;
      width: 100px;
      height: 100%;
      outline: none;
      background-color: transparent;
      cursor: pointer;
      transition: all 300ms;
  
      svg{
        fill: #B1B1B1;
        width: 20px;
        height: 20px;
        transition: all 300ms;
      }
  
      &:hover{
        background-color: rgba(27,67,128,1);
        
        svg{
          fill: #FFFFFF;
        }
      }
    }
  }
}

.event-card{
  background-color: #EBEBEB;
  margin-bottom: 16px;

  .event-body{
    padding: 16px;
  }

  .event-footer{
    border-top: 1px solid #D8D8D8;
    padding: 16px;

    a{
      font-size: 12px;
      font-weight: 500;
      color: #EE752F;

      svg{
        fill: #EE752F;
      }
    }
  }
}

.member-card{
  width: 100%;
  background-color: #FDFDFD;
  margin-bottom: 16px;
  min-height: 120px;
  padding: 16px;
  
  border-radius: 4px;
  border: 1px solid #f1f1f1;
  
  .member-header{
    display: flex;
    align-items: flex-start;
    .m-left{
      display: inline-flex;
      flex-direction: column;

      .avatar-inner{
        width: 120px;
        height: 140px;
        overflow: hidden;
        background-color: #909090;
        border-radius: 8px;
        position: relative;

        .avatar{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
  
        .badge-level{
          font-weight: 300;
          font-size: 10px;
          text-transform: uppercase;
          position: absolute;
          bottom: 0px;
          left: 0px;
          right: 0px;
          margin-bottom: 0px;
          text-transform: uppercase;
          color: #FFFFFF;
          height: 24px;
          line-height: 24px;
          & > img{
            width: 48px;
            height: 48px;
            margin-top: -28px;
            margin-left: 8px;
            margin-right: 4px;
          }
        }
      }
    }
  
    .m-right{
      padding-left: 16px;
      .members-social-links{
        margin-bottom: 16px;
        & > a{
          margin-right: 16px;
          svg{
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  p{
    margin-bottom: 0px;
  }
}


.countdown-timer-container{

  background-color: #EBEBEB;
  margin-top: 80px;
  padding: 28px;

  &>h3{
    font-weight: 200;
  }

  .countdown-timer{
    display:flex;
    align-items: center;
    justify-content: center;
    
    margin-top: 20px;

    &>div{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 70px;
      margin: 0px 22px;

      .cnt-label{
        font-size: 12px;
        font-weight: 200;
        letter-spacing: 0.02em;
        text-transform: capitalize;
        line-height: 149%;
        color: #1C4380;
      }

      .cnt-value{
        font-size: 48px;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: 0.02em;
        text-transform: capitalize;
        color: #1C4380;
      }
    }
  }
}

.search-modal{
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #FFFFFF;
  z-index: 9999;
  height: 100%;

  .close-me{
    position: absolute;
    right: 20px;
    top: 20px;
    /*border: 2px solid #909090;*/
    width: 60px;
    height: 60px;
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    align-self: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9999;
  }

  .layout{
    margin: 28px;
    margin-top: 40px;
    border: 0px solid;
    position: relative;
    display: block;
    overflow-y: auto;
    height: 100%;
    padding-bottom: 80px;
  }

  .m-search-input{
    width: 100%;
    border: none;
    font-size: 4.26rem;
    background-color: transparent;
    font-weight: 300;
    margin-bottom: 1.33rem;
    color: #595c97;

    &:focus{
      outline: 0px;
    }
  }

  .search-item{
    display: flex;
    align-items: top;
    margin-bottom: 8px;

    &>.search-thumb{
      &>img{
        width: 50px;
        height: 50px;
        overflow: hidden;
        object-fit: cover;
        border-radius: 8px;
        margin-right: 8px;
        background-color: #A5D4E1;
      }

      &>span{
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 8px;
        margin-right: 8px;
        background-color: #A5D4E1;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 300;
        font-size: 1.6rem;
      }
    }

    &>.search-content{
      display: inline-flex;
      justify-content: center;
      flex-direction: column;

      .search-primary-text{
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
}

.typeform-widget{
  width: 100%;
  height: 700px;
  display: block;
}

@include media-breakpoint-down(md){
  .search-modal{
    .m-search-input{
      font-size: 2.26rem;
    }
  }
  .author-page{
    .author-card{
      display: flex;
      flex-direction: column;
      align-items: center;
      .avatar-inner{

      }

      .right-box{
        margin-top: 16px;

        .breadcrum{
          text-align: center;
          display: none;
        }

        .author-name{
          font-size: 30px;
          text-align: center;
          line-height: 30px;
        }

        .author-job-title{
          text-align: center;
          font-size: 16px;
        }

        .author-badges{
          display: flex;
          align-items: center;
          .badge-img{
            width: 48px;
          }
          .badge-point{
            font-size: 14px;
          }
        }

        .author-social-links{
          text-align: center;
        }
      }
    }
  }
}

.edit-profile{
  .profile-badge-container{
    display: flex;
    align-items: center;
  }

  .avatar-inner{
    width: 120px;
    height: 150px;
    overflow: hidden;
    background-color: #909090;
    border-radius: 8px;
    position: relative;

    .avatar{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .badge-level{
      font-weight: 300;
      font-size: 10px;
      text-transform: uppercase;
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      margin-bottom: 0px;
      text-transform: uppercase;
      color: #FFFFFF;
      height: 24px;
      line-height: 24px;
      & > img{
        width: 48px;
        height: 48px;
        margin-top: -28px;
        margin-left: 8px;
        margin-right: 4px;
      }
    }
  }

  .right-box{
    padding-left: 28px;
    .author-name{
      font-size: 34px;
      line-height: 34px;
      font-weight: 200;
      color: #575555;
    }

    .author-job-title{
      font-size: 20px;
      line-height: 22px;
      font-weight: 200;
      color: #313131;
    }

    .author-badges{
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      .badge-img{
        width: 48px;
        height: auto;
        margin-right: 8px;
      }

      .badge-point{
        font-size: 22px;
        font-weight: 100;
        color: #1B4380;
      }
    }
  }
}

.badge-status{
  width: 100%;
  text-align: right;
  padding-right: 16px;
  & > div{
    display: inline-block;
    margin-left: 20px;
    & > label{
      font-size: 18px;
      font-weight: 200;
      color: #45BDBF;
      cursor: pointer;
    }
  }
}
.badge-progress-container{
  width: 100%;
  overflow-x: auto;
  
  .badge-progress-strip{
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 768px;
    height: 210px;

    & > .badge-node{
      background-color: #EF752F;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 4px solid #909090;
      position: relative;
      cursor: pointer;

      &.main{
        width: 56px;
        height: 56px;
      }

      &.active{
        border-color:#45BDBF;
      }

      &::after{
        content: ' ';
        background-color: #EF752F;
        position: absolute;
        width: 60px;
        height: 6px;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
        border: 2px solid #909090;
      }

      &.active::after{
        border: 2px solid #45BDBF;
      }

      &:last-child:after{
        content: ' ';
        width: 0px;
      }

      &>.badge-tooltip{
        position: absolute;
        top: -80px;
        left: 0px;
        min-width: 300px;
        background-color: #45BDBF;
        border-radius: 8px;
        padding: 4px;
        z-index: 9;
        width: auto;

        .tooltip-arrow{
          position: absolute;
          width: 0;
          height: 0;
          border-color: transparent;
          border-right-color: transparent;
          border-style: solid;
          top: auto;
          bottom: -10px;
          left: 10%;
          margin-left: -20px;
          border-width: 15px 15px 0;
          border-top-color: #45BDBF; 
        }

        .tooltip-label{
          color: #fff;
          text-align: center;
        }

        &.last-item{
          right: 0px;
          left: auto;

          .tooltip-arrow{
            right: 10%;
            margin-right: -20px;
            left: auto;
            margin-left: auto;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md){
  .my-content-page .heading h1{
    font-size: 22px;
    line-height: 22px;
    text-align: center;
  }
  .it-breadcrumb{
    display: none;
  }
  .my-content-page h5{
    text-align: center;
  }
  .badge-status{
    text-align: center;
  }
  .badge-progress-container .badge-progress-strip{
    height: 210px;
  }

  .edit-profile{
    .profile-badge-container{
      flex-direction: column;
    }
    .right-box{
      padding: 0px;
      .author-name{
        text-align: center;
        margin-top: 100px;
      }
      .author-job-title{
        text-align: center;
      }
      .author-badges{
        .badge-point{
          font-size: 18px;
        }
      }
    }

    h6{
      text-align: center;
    }
  }
  .user-image-upload{
    margin-top: -210px;
    width: 100%;
    justify-content: center;

    div.img-input-field label{
      padding: 4px 20px;
    }
  }
}

@media screen and (max-width: 1280px){

  .event-reg-home-carousel{
    .custom-slider-item{
      height: auto;
      display: flex;
      flex-direction: column;
      min-height: auto;
    }
  }

  .event-reg-home-carousel .event-post-date {
      position: unset;
      height:auto;
  }
  .event-reg-home-carousel .event-post-date .custom-page-content {
      padding: 15px 16px;
  }
  .custom-page-content-prefix {
      padding: 40px 16px;
  }
  .event-reg-home-carousel .event-post-date .custom-page-content p{
    line-height: 1.5rem;
  }
  .custom-page-content-prefix .speaker_image img {
      width: 100%;
  }
  p {
      line-height: 23px;
  }
  .content-blockplacer-form.w-50.m-auto.pt-5.text-left {
      width: 95% !important;
      margin-bottom: 30px !important;
  }
}

.event-register-button {
    background-color: #21bdbe;
    border-color: #21bdbe;
    border-radius: 0;
    border-radius: 5px;
    margin: 15px 0;
}
.sudoHeading{
  margin-bottom:25px;
}
.content-blockplacer.content-blockplacer-mw-90 {
    padding-right: 30px;
}
.textAliecenter{
  text-align: center;
}